import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  onboardingProgress: null,
  isSandboxMode: false,
  currency: 'USD',
  checkoutConfigs: {
    isExpressCheckout: false,
    tributeDonation: false,
    tributeDonationExpress: false,
    enableQuickPay: false,
    coverFee: false,
    coverFeeExpress: false,
    anonymousDonation: false,
    anonymousDonationExpress: false,
    subscribeMailingList: false,
    subscribeMailingListExpress: false,
    includeDonationSupportTemplates: false,
    donationSupportType: 'multi-step',
    donationSupportTemplates: ['', '', ''],
    allowStipulation: false,
    stipulations: [],
    giftAid: false,
    collectAddress: false,
    allowAdminFee: false,
    hideRecentDonations: false,
    hideFundraisingBar: false,
    enableFundraiserSupport: false,
    askDonorTitle: false,
    askDonorPhoneNumber: false,
  },
};

const slice = createSlice({
  name: 'orgSettings',
  initialState,
  reducers: {
    setOnboardingProgress: (state, { payload }) => {
      state.onboardingProgress = payload;
    },
    setSandboxMode: (state, { payload }) => {
      state.isSandboxMode = payload;
    },
    setOrgCurrency: (state, { payload }) => {
      state.currency = payload;
    },
    setOrgCheckoutConfigs: (state, { payload }) => {
      state.checkoutConfigs = payload;
    },
  },
});

// ACTIONS
export const { setOnboardingProgress, setSandboxMode, setOrgCurrency, setOrgCheckoutConfigs } = slice.actions;

// SELECTORS
const selectOrgSettingsData = (state) => {
  return state.orgSettings;
};

export const selectOnboardingProgress = createSelector(selectOrgSettingsData, (data) => data.onboardingProgress);
export const selectSandboxMode = createSelector(selectOrgSettingsData, (data) => data.isSandboxMode);
export const selectOrgCurrency = createSelector(selectOrgSettingsData, (data) => data.currency);
export const selectCheckoutConfigs = createSelector(selectOrgSettingsData, (data) => data.checkoutConfigs);

export default slice.reducer;
