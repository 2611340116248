// donations.js
import queryString from 'query-string';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

export const fetchFundsDataByOrganizationId = async ({ queryKey }) => {
  try {
    const [_key, { authToken, organizationId, params }] = queryKey;

    const result = await fetch(
      `${API_URL}/api/fund/get-by-organization/${organizationId}?${queryString.stringify(params)}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'authorization': authToken,
        },
      }
    );
    const parsedResult = await result.json();
    if (!result.ok) {
      throw parsedResult;
    }
    const fundsList = parsedResult.data || [];
    fundsList.sort((a, b) => a.name.localeCompare(b.name));
    return fundsList;
  } catch (error) {
    throw error;
  }
};

export const fetchFundsDataById = async ({ queryKey }) => {
  try {
    const [_key, { authToken, id }] = queryKey;

    const result = await fetch(`${API_URL}/api/orginzation-fund/get/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
    });
    const parsedResult = await result.json();
    if (!result.ok) {
      throw parsedResult;
    }
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const fetchAllFunds = async ({ queryKey }) => {
  try {
    const [_key, { authToken }] = queryKey;

    const result = await fetch(`${API_URL}/api/fund/get/`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
    });
    const parsedResult = await result.json();
    if (!result.ok) {
      throw parsedResult;
    }
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const fetchFundById = async ({ queryKey }) => {
  try {
    const [_key, { authToken, id }] = queryKey;

    const result = await fetch(`${API_URL}/api/fund/get/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
    });
    const parsedResult = await result.json();
    if (!result.ok) {
      throw parsedResult;
    }
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const fetchFundSubCategoryById = async ({ queryKey }) => {
  try {
    const [_key, { authToken, fundSubCategoryId, query }] = queryKey;

    const result = await fetch(
      `${API_URL}/api/fund-subcategory/${fundSubCategoryId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'authorization': authToken,
        },
      }
    );
    const parsedResult = await result.json();
    if (!result.ok) {
      throw parsedResult;
    }
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const fetchFundSubCategoryByIdNonQuery = async (fundSubCategoryId) => {
  try {
    const result = await fetch(`${API_URL}/api/fund-subcategory/${fundSubCategoryId}`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
    });
    const parsedResult = await result.json();
    if (!result.ok) throw parsedResult;
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const fetchFundSubCategories = async ({ queryKey }) => {
  try {
    const [_key, { authToken, organizationId, fundId, query }] = queryKey;

    const result = await fetch(
      `${API_URL}/api/fund-subcategory?${new URLSearchParams({
        organizationId,
        ...(fundId ? { fundId } : {}),
        ...(query ? { query } : {}),
      })}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'authorization': authToken,
        },
      }
    );
    const parsedResult = await result.json();
    if (!result.ok) {
      throw parsedResult;
    }
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const fetchFormMamaFundSubCategory = async ({ queryKey }) => {
  try {
    const [_key, { authToken, organizationId }] = queryKey;

    const result = await fetch(`${API_URL}/api/fund-subcategory/formama/${organizationId}`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
    });
    const parsedResult = await result.json();
    if (!result.ok) {
      throw parsedResult;
    }
    return parsedResult.data;
  } catch (error) {
    throw error;
  }
};

export const createFundSubCategory = async ({ authToken, payload }) => {
  try {
    const result = await fetch(`${API_URL}/api/fund-subcategory`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });
    const parsedResult = await result.json();
    if (!result.ok) throw parsedResult;
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const createN3OFundSubCategory = async ({ authToken, payload }) => {
  try {
    const result = await fetch(`${API_URL}/api/fund-subcategory/n3o-funds`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });
    const parsedResult = await result.json();
    if (!result.ok) throw parsedResult;
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const updateFundSubCategory = async ({ id, authToken, payload }) => {
  try {
    const result = await fetch(`${API_URL}/api/fund-subcategory/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      method: 'PATCH',
      body: JSON.stringify(payload),
    });
    const parsedResult = await result.json();
    if (!result.ok) throw parsedResult;
    return parsedResult;
  } catch (error) {
    throw error;
  }
};

export const updateN3OFundSubCategory = async ({ id, authToken, payload }) => {
  try {
    const result = await fetch(`${API_URL}/api/fund-subcategory/n3o-funds/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      method: 'PATCH',
      body: JSON.stringify(payload),
    });
    const parsedResult = await result.json();
    if (!result.ok) throw parsedResult;
    return parsedResult;
  } catch (error) {
    throw error;
  }
};